html {
    height: 100%;
}

body {
    height: 100%;
}

:not(:root):fullscreen::backdrop {
    background-color: #f9f9f9;
}

.appbar {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
}

/* *:after {
    content: "♻";
    position: absolute;
    top: 0;
    left: 0;
    color: #0D47A1;
    font-size: 72px;
    opacity: 0.5;
    -webkit-animation: spin 2s ease infinite;
    -moz-animation: spin 2s ease infinite;
    animation: spin 2s ease infinite;
} */

/* * {
    -webkit-animation: spin 30s ease infinite;
    -moz-animation: spin 30s ease infinite;
    animation: spin 30s ease infinite;
} */

#indexjs > div:first-child {
    height: 100%;
}

#main-grid {
    /* Fallback for Edge where inline styles with javascript (theme) doesn't work */
    padding-top: 64px;
}

.carousel__slide-focus-ring {
    outline: none !important;
    -webkit-appearance: none !important;
}
.slider-custom-slider-inner {
    display: flex;
    height: 100%;
}
.slider-custom-slider-inner > div {
    width: 100%;
}
.slider-custom-slider-inner > .carousel {
    height: 100%;
}
.slider-custom-slider-inner > div > div {
    height: 100%;
}
.slider-slide-root,
.slider-slide-root:focus {
    outline: none !important;
    -webkit-appearance: none !important;
}
.slider-slide-inner {
    position: initial;
    top: initial;
    left: initial;
    height: 100%;
}
.slider-custom-animation {
    transition: transform 500ms;
    transition-timing-function: ease; /* easeInOutCubic */
    will-change: transform;
}

.offset-legal-info .gm-style-cc {
    margin-bottom: 135px !important;
}

.offset-legal-info .gm-style-cc > div > div {
    background: transparent !important;
}

.offset-legal-info a[href^="http://maps.google.com/maps"],
.offset-legal-info a[href^="https://maps.google.com/maps"],
.offset-legal-info a[href^="https://www.google.com/maps"] {
    position: relative !important;
}

.add-right-offset-legal-info a[href^="http://maps.google.com/maps"],
.add-right-offset-legal-info a[href^="https://maps.google.com/maps"],
.add-right-offset-legal-info a[href^="https://www.google.com/maps"] {
    margin-right: 70px !important;
}

.hide-legal-info .gm-style-cc {
    display: none;
}

.hide-legal-info .gm-style-cc > div > div {
    display: none;
}

.hide-legal-info a[href^="http://maps.google.com/maps"] > div,
.hide-legal-info a[href^="https://maps.google.com/maps"] > div,
.hide-legal-info a[href^="https://www.google.com/maps"] > div {
    display: none;
}

.gradual-transition-scroll {
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    transition: all 0.15s linear;
}

.gradual-transition {
    -webkit-transition: top 0.225s cubic-bezier(0, 0, 0.2, 1) !important;
    -moz-transition: top 0.225s cubic-bezier(0, 0, 0.2, 1) !important;
    transition: top 0.225s cubic-bezier(0, 0, 0.2, 1) !important;
}

.slow-spin {
    -webkit-animation: spin 24s linear infinite;
    -moz-animation: spin 24s linear infinite;
    animation: spin 24s linear infinite;
}

.select-icon > div > svg {
    margin-right: 24px;
}

.marker-label {
    font-size: 20px;
    width: 51px;
    height: 52px;
}

.marker-label .pickup-count-label {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.marker-label .above-count-container {
    width: 100px;
    height: 52px;
    transform: translate(-24px, -46px);
    -webkit-transform: translate(-24px, -46px);
    color: black;
    font-size: 12px;
    font-weight: 700;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}

.marker-label .return-site {
    /* position: fixed; */
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

/* 
Set the Zoho widget under modals which are 1300
 */
#zohohc-asap-web-launcherbox {
    z-index: 1299;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

#custom-scroller * {
    /* don't allow the children of the scrollable element to be selected as an anchor node */
    overflow-anchor: none;
}

#custom-anchor {
    /* allow the final child to be selected as an anchor node */
    overflow-anchor: auto;

    /* anchor nodes are required to have non-zero area */
    height: 1px;
}

.animated-background {
    background-image: linear-gradient(180deg, hsl(170, 25%, 40%), hsl(240, 30%, 40%), hsl(350, 25%, 40%));
    background-size: 600% 600%;
    background-repeat: no-repeat;
    background-attachment: fixed;

    -webkit-animation: backgroundAnimation 30s ease infinite;
    -moz-animation: backgroundAnimation 30s ease infinite;
    animation: backgroundAnimation 30s ease infinite;
}

@-webkit-keyframes backgroundAnimation {
    0% {
        background-position: 50% 0%;
    }
    50% {
        background-position: 50% 100%;
    }
    100% {
        background-position: 50% 0%;
    }
}
@-moz-keyframes backgroundAnimation {
    0% {
        background-position: 50% 0%;
    }
    50% {
        background-position: 50% 100%;
    }
    100% {
        background-position: 50% 0%;
    }
}
@keyframes backgroundAnimation {
    0% {
        background-position: 50% 0%;
    }
    50% {
        background-position: 50% 100%;
    }
    100% {
        background-position: 50% 0%;
    }
}

.animation-bubble {
    -webkit-animation: bubble 0.3s ease-in-out;
    -moz-animation: bubble 0.3s ease-in-out;
    animation: bubble 0.3s ease-in-out;
}
@-webkit-keyframes bubble {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(2);
    }
    100% {
        transform: scale(1);
    }
}
@-moz-keyframes bubble {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(2);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes bubble {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(2);
    }
    100% {
        transform: scale(1);
    }
}

@media (min-width: 960px) {
    .scalingFont {
        font-size: 10px;
    }
}

@media (min-width: 1280px) {
    .scalingFont {
        font-size: 12px;
    }
}

@media (min-width: 1600px) {
    .scalingFont {
        font-size: 16px;
    }
}

@media (min-width: 1920px) {
    .scalingFont {
        font-size: 18px;
    }
}

.fade-in {
    animation: fadeIn ease 0.4s;
    -webkit-animation: fadeIn ease 0.4s;
    -moz-animation: fadeIn ease 0.4s;
    -o-animation: fadeIn ease 0.4s;
    -ms-animation: fadeIn ease 0.4s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.gm-style iframe + div {
    border: none !important;
}

/* Styling for React-Draft-wysiwyg */

.rdw-image-modal-header {
    margin: unset;
}
